#userIcon {
  padding: 10px 15px 10px 15px;
  border-radius: 50%;
  background-color: lightgreen;
  font-size: 13px;
  color: white;
  font-weight: bold;
  text-transform: capitalize;
}
#profile {
  display: none;
}
#navbar {
  box-shadow: rgb(38, 57, 77) 0px 0px 10px 0px;
  height: 54px;
}
@media (max-width: 767px) {
  #userIcon {
    display: none;
  }
  #profile {
    display: block;
  }
}

#dropdown {
  padding: 0;
  margin: 0;
}
