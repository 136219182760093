.leaflet-container {
  height: calc(100% - 57px);
  width: 100%;
  position: absolute !important;
}
.searchWrapper {
  max-width: 548px;
  overflow-y: scroll;
}
.leaflet-popup-content {
  padding: 0px;
  margin: 0px;
}
.leaflet-popup-content-wrapper {
  padding: 0px;
  margin: 0px;
}
.leaflet-tile {
  border: solid grey 0.5px;
}
.dropdown-toggle::after {
  display: none !important;
}
