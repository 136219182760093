#multiSelect span {
  background: grey;
  border-radius: 100px;
}
#addItemButton {
  background: none;
  border: none;
  border-radius: 100px;
  float: right;
  color: #0a82c6;
}
#dateRange {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}
#buttonsContainer {
  float: right;
}
#discardButton {
  background: none;
  border: 1px solid #0a82c6;
  border-radius: 100px;
  color: #0a82c6;
}
#saveButton {
  background: #0a82c6;
  border: none;
  border-radius: 100px;
  color: white;
  margin-left: 10px;
}
#formLabel {
  font-family: sans-serif;
  margin-bottom: 0.7em;
}
