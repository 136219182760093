#popup {
  min-width: 200px;
  border-radius: 10px;
}
#heading {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #f2f2f2;
  font-weight: bold;
  padding-bottom: 10px;
}
#body {
  padding: 10px 10px 10px 10px;
}
#heading p {
  padding: 15px 10px 10px 10px;
}
#body li {
  color: grey;
}
#footer {
  padding: 0px 10px 30px 10px;
}
#li {
  margin-bottom: 10px;
}
